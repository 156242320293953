/* eslint-disable max-len */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { MixType } from 'Type/Common.type';

import './loader.style.scss';

/**
 * Loader component
 * Loaders overlay to identify loading
 * @class Loader
 * @namespace ScandiDemo/Component/Loader */
export class Loader extends PureComponent {
  static propTypes = {
      isLoading: PropTypes.bool,
      mix: MixType
  };

  static defaultProps = {
      isLoading: true,
      mix: {}
  };

  render() {
      const { isLoading, mix } = this.props;

      if (!isLoading) {
          return null;
      }

      return (
      <div className="Loader" mix={ mix }>
        <svg
          className="Car"
          width="102"
          height="40"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g
            transform="translate(2 1)"
            stroke="var(--theme-color)"
            fill="none"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path
              className="Car__body"
              d="M47.293 2.375C52.927.792 54.017.805 54.017.805c2.613-.445 6.838-.337 9.42.237l8.381 1.863c2.59.576 6.164 2.606 7.98 4.531l6.348 6.732 6.245 1.877c3.098.508 5.609 3.431 5.609 6.507v4.206c0 .29-2.536 4.189-5.687 4.189H36.808c-2.655 0-4.34-2.1-3.688-4.67 0 0 3.71-19.944 14.173-23.902zM36.5 15.5h54.01"
              strokeWidth="3"
            />
            <ellipse
              className="Car__wheel--left"
              strokeWidth="3.2"
              fill="#FFF"
              cx="83.493"
              cy="30.25"
              rx="6.922"
              ry="6.808"
            />
            <ellipse
              className="Car__wheel--right"
              strokeWidth="3.2"
              fill="#FFF"
              cx="46.511"
              cy="30.25"
              rx="6.922"
              ry="6.808"
            />
            <path
              className="Car__line Car__line--top"
              d="M22.5 16.5H2.475"
              strokeWidth="3"
            />
            <path
              className="Car__line Car__line--middle"
              d="M20.5 23.5H.4755"
              strokeWidth="3"
            />
            <path
              className="Car__line Car__line--bottom"
              d="M25.5 9.5h-19"
              strokeWidth="3"
            />
          </g>
        </svg>
      </div>
      );
  }
}

export default Loader;
