/* eslint-disable no-case-declarations */
// TODO update this import when renamed
import {
    ACCORDIAN_ACTION_TYPE,
    APPROVAL_MESSAGE,
    CARPLATE_NUMBER_ACTION_TYPE,
    CATEGORY_ACTION_TYPE,
    CATEGORY_UPDATE_ACTION_TYPE,
    CHANGE_THEME_COLOR,
    CHECKOUT_COMPLETE_ORDER_ACTION_TYPE,
    CHECKOUT_TOGGLE_ACTION_TYPE,
    CURRENCY_ACTION_TYPE,
    FILTER_CATEGORY_ACTION_TYPE,
    SEARCH_MODEL_ACTION_TYPE,
    SEARCH_PRODUCT_DATA_ACTION_TYPE,
    SUPER_ADMIN_CHANGE_TOGGLE,
    SUPER_ADMIN_STATUS
} from './Savedata.action';

/** @namespace ScandiDemo/Store/Savedata/Reducer/getInitialState */
export const getInitialState = () => ({
    category: [],
    model: {},
    filterCategory: [],
    items: [],
    categoryupdate: false,
    checkouttoggle: true,
    completeOrders: false,
    searchNumber: null,
    currency: '',
    expand: '',
    status: {},
    ChangeToggle: false,
    message: '',
    theme: {}
});

/** @namespace ScandiDemo/Store/Savedata/Reducer/SavedataReducer */
export const SavedataReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case CATEGORY_ACTION_TYPE:
        const { category } = action;
        return {
            ...state,
            // TODO implement payload handling
            category
        };
    case SEARCH_MODEL_ACTION_TYPE:
        const { model } = action;
        return {
            ...state,
            model
        };

    case FILTER_CATEGORY_ACTION_TYPE:
        const { fiterCategory } = action;

        return {
            ...state,
            fiterCategory
        };

    case SEARCH_PRODUCT_DATA_ACTION_TYPE:
        const { items } = action;

        return {
            ...state,
            items
        };

    case CATEGORY_UPDATE_ACTION_TYPE:
        const { categoryupdate } = action;

        return {
            ...state,
            categoryupdate
        };

    case CHECKOUT_TOGGLE_ACTION_TYPE:
        const { checkouttoggle } = action;

        return {
            ...state,
            checkouttoggle
        };

    case CHECKOUT_COMPLETE_ORDER_ACTION_TYPE:
        const { completeOrders } = action;

        return {
            ...state,
            completeOrders
        };

    case CARPLATE_NUMBER_ACTION_TYPE:
        const { searchNumber } = action;

        return {
            ...state,
            searchNumber
        };

    case CURRENCY_ACTION_TYPE:
        const { currency } = action;

        return {
            ...state,
            currency
        };

    case ACCORDIAN_ACTION_TYPE:
        const { expand } = action;

        return {
            ...state,
            expand
        };

    case SUPER_ADMIN_STATUS:
        const { status } = action;

        return {
            ...state,
            status
        };

    case SUPER_ADMIN_CHANGE_TOGGLE:
        const { ChangeToggle } = action;

        return {
            ...state,
            ChangeToggle
        };

    case APPROVAL_MESSAGE:
        const { message } = action;

        return {
            ...state,
            message
        };

    case CHANGE_THEME_COLOR:
        const { theme } = action;

        return {
            ...state,
            theme
        };

    default:
        return state;
    }
};

export default SavedataReducer;
