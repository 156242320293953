/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';

/**
  * Loader component
  * Loaders overlay to identify loading
  * @class Loader
  * @namespace Component/Loader/Component
  */
import {
    Loader as SourceLoader
} from 'SourceComponent/Loader/Loader.component';
import { MixType } from 'Type/Common.type';

import './Loader.style';

/** @namespace ScandiDemo/Component/Loader/Component */
export class LoaderComponent extends SourceLoader {
    static propTypes = {
        isLoading: PropTypes.bool,
        mix: MixType
    };

    static defaultProps = {
        isLoading: true,
        mix: {}
    };

    renderMain() {
        return (
            <div block="Loader" elem="Main">
                <span />
            </div>
        );
    }

    render() {
        const { isLoading, mix } = this.props;

        if (!isLoading) {
            return null;
        }

        return (
            <div block="Loader">
                <div block="Loader" mix={ mix } elem="Scale">
                    { this.renderMain() }
                </div>
            </div>
        );
    }
}

export default LoaderComponent;
