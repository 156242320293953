/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

// TODO: maybe consider moving to IndexedDB insead of localStorage

/**
 * Set of helpers related to Browser Database
 * @class CSS
 * @namespace ScandiDemo/Util/BrowserDatabase */
export class BrowserDatabase {
    /**
   * Loads data from browser storage
   * @param {String} location Name of the local storage
   * @return {Object} Object stored in a specified path
   * @memberof BrowserDatabase
   */
    getItem(location) {
        try {
            const entryObject = JSON.parse(localStorage.getItem(location));
            const { data, expiration, createdAt } = entryObject;
            const MILLISECONDS_TO_SECONDS = 1000;

            if (
                expiration
        && Date.now() - createdAt > expiration * MILLISECONDS_TO_SECONDS
            ) {
                localStorage.removeItem(location);
                localStorage.removeItem('sid');
                localStorage.removeItem('quoteId');
                localStorage.removeItem('categoryId');
                localStorage.removeItem('addCart');
                localStorage.removeItem('carlinkment');
                localStorage.removeItem('modelid');

                return null;
            }

            return data;
        } catch {
            return null;
        }
    }

    /**
   * Save data to local storage
   * @param {Any} data The value to save to local storage
   * @param {String} location Name of the local storage
   * @param {Number} expiration Time to store entry (in seconds)
   * @return {Void}
   * @memberof BrowserDatabase
   */
    setItem(data, location, expiration) {
        localStorage.setItem(
            location,
            JSON.stringify({
                data,
                expiration,
                createdAt: Date.now()
            })
        );
    }

    /**
   * Delete item from local storage
   * @param {String} location
   * @memberof BrowserDatabase
   */
    // delete localstorages //
    deleteItem(location) {
        localStorage.removeItem(location);
    }
}

export default new BrowserDatabase();
