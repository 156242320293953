/* eslint-disable react/prop-types */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { isSignedIn } from 'Util/Auth';

/** @namespace ScandiDemo/Component/Router/ProtectedRoute/PrivateRoute */
export const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      { ...rest }
      render={ (props) => (isSignedIn() ? (
          <Component { ...props } />
      ) : (
          <Redirect to={ { pathname: '/customer/account/login/' } } />
      )) }
    />
);
