// TODO rename
export const CATEGORY_ACTION_TYPE = 'CATEGORY_ACTION_TYPE';
export const SEARCH_MODEL_ACTION_TYPE = 'SEARCH_MODEL_ACTION_TYPE';
export const FILTER_CATEGORY_ACTION_TYPE = 'FILTER_CATEGORY_ACTION_TYPE';
export const SEARCH_PRODUCT_DATA_ACTION_TYPE = 'SEARCH_PRODUCT_DATA_ACTION_TYPE';
export const CATEGORY_UPDATE_ACTION_TYPE = 'CATEGORY_UPDATE_ACTION_TYPE';
export const CHECKOUT_TOGGLE_ACTION_TYPE = 'CHECKOUT_TOGGLE_ACTION_TYPE';
export const CHECKOUT_COMPLETE_ORDER_ACTION_TYPE = 'CHECKOUT_COMPLETE_ORDER_ACTION_TYPE';
export const CARPLATE_NUMBER_ACTION_TYPE = 'CARPLATE_NUMBER_ACTION_TYPE';
export const CURRENCY_ACTION_TYPE = 'CURRENCY_ACTION_TYPE';
export const ACCORDIAN_ACTION_TYPE = 'ACCORDIAN_ACTION_TYPE';
export const SUPER_ADMIN_STATUS = 'SUPER_ADMIN_STATUS';
export const SUPER_ADMIN_CHANGE_TOGGLE = 'SUPER_ADMIN_CHANGE_TOGGLE';
export const APPROVAL_MESSAGE = 'APPROVAL_MESSAGE';
export const CHANGE_THEME_COLOR = 'CHANGE_THEME_COLOR';

// TODO rename
/** @namespace ScandiDemo/Store/Savedata/Action/categoryAction */
export const categoryAction = (category) => ({
    type: CATEGORY_ACTION_TYPE,
    category
});

/** @namespace ScandiDemo/Store/Savedata/Action/searchModelAction */
export const searchModelAction = (model) => ({
    type: SEARCH_MODEL_ACTION_TYPE,
    model
});

/** @namespace ScandiDemo/Store/Savedata/Action/filterCategoryAction */
export const filterCategoryAction = (filtercategory) => ({
    type: FILTER_CATEGORY_ACTION_TYPE,
    filtercategory
});

/** @namespace ScandiDemo/Store/Savedata/Action/searchProductData */
export const searchProductData = (items) => ({
    type: SEARCH_PRODUCT_DATA_ACTION_TYPE,
    items
});

/** @namespace ScandiDemo/Store/Savedata/Action/categoryUpdate */
export const categoryUpdate = (categoryupdate) => ({
    type: CATEGORY_UPDATE_ACTION_TYPE,
    categoryupdate
});

/** @namespace ScandiDemo/Store/Savedata/Action/CheckoutToggle */
export const CheckoutToggle = (checkouttoggle) => ({
    type: CHECKOUT_TOGGLE_ACTION_TYPE,
    checkouttoggle
});

/** @namespace ScandiDemo/Store/Savedata/Action/CheckoutPlaceOrderStatus */
export const CheckoutPlaceOrderStatus = (completeOrders) => ({
    type: CHECKOUT_COMPLETE_ORDER_ACTION_TYPE,
    completeOrders
});

/** @namespace ScandiDemo/Store/Savedata/Action/carPlateSearchNumber */
export const carPlateSearchNumber = (searchNumber) => ({
    type: CARPLATE_NUMBER_ACTION_TYPE,
    searchNumber
});

/** @namespace ScandiDemo/Store/Savedata/Action/currencySymbole */
export const currencySymbole = (currency) => ({
    type: CURRENCY_ACTION_TYPE,
    currency
});

/** @namespace ScandiDemo/Store/Savedata/Action/accordianExpand */
export const accordianExpand = (expand) => ({
    type: ACCORDIAN_ACTION_TYPE,
    expand
});

/** @namespace ScandiDemo/Store/Savedata/Action/superAdminStatus */
export const superAdminStatus = (status) => ({
    type: SUPER_ADMIN_STATUS,
    status
});

/** @namespace ScandiDemo/Store/Savedata/Action/supderAdminChangesTiggle */
export const supderAdminChangesTiggle = (ChangeToggle) => ({
    type: SUPER_ADMIN_CHANGE_TOGGLE,
    ChangeToggle
});

/** @namespace ScandiDemo/Store/Savedata/Action/approvalMessage */
export const approvalMessage = (message) => ({
    type: APPROVAL_MESSAGE,
    message
});

/** @namespace ScandiDemo/Store/Savedata/Action/changeThemeColor */
export const changeThemeColor = (theme) => ({
    type: CHANGE_THEME_COLOR,
    theme
});
